import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import colors from '@nib-components/colors';
import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Card from '@nib/card';
import { Section, Stack } from '@nib/layout';
import {
  HealthInsuranceGraphicIcon,
  WellnessScoreGraphicIcon,
  ComputerAiGraphicIcon,
  PriceGraphicIcon,
  MultiYearPartnershipsGraphicIcon,
} from '@nib/icons';
import { colorTrueGreen } from '@nib-components/theme';

import HeroPanel from '../components/HeroPanel';
import Layout from '../components/Layout';
import UtilityButtons from '../components/UtilityButtons';
import VerticalFeaturePanel from '../components/VerticalFeaturePanel';
import metrics from '../metrics';
import config from '../utils/env';
import { ContentfulDocument, UtilityLinkModel } from '../constructs/models';

// images
import DesktopHero from '../img/adviser/hero/desktop.jpg';
import DesktopHero2x from '../img/adviser/hero/desktop@2x.jpg';
import MobileHero from '../img/adviser/hero/mobile.jpg';
import MobileHero2x from '../img/adviser/hero/mobile@2x.jpg';
import TabletHero from '../img/adviser/hero/tablet.jpg';
import TabletHero2x from '../img/adviser/hero/tablet@2x.jpg';
import Step01Img from '../img/adviser/steps/01.jpg';
import Step02Img from '../img/adviser/steps/02.jpg';
import Step03Img from '../img/adviser/steps/03.jpg';
import Step04Img from '../img/adviser/steps/04.jpg';
import Step05Img from '../img/adviser/steps/05.jpg';
import { HeroPanelCopy } from '../components/styledComponents';

const title = 'nib Adviser Login';
const metaDescription =
  'nib adviser access gives you the information to support your nib client policies. Become an nib adviser or request a login if you are new to nibAPPLY.';

export const AdviserPageQuery = graphql`
  query AdviserPageQuery {
    keyContactList: allContentfulDocument(
      filter: { name: { eq: "Advisor Key Contact List" } }
      limit: 1
    ) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
    helpDoc: allContentfulDocument(filter: { name: { eq: "Help Doc" } }, limit: 1) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
  }
`;

const utilityLinks = (helpDoc: ContentfulDocument): UtilityLinkModel[] => [
  {
    title: 'Request a login',
    subtitle: 'New to adviser access?',
    url: 'mailto:advisersupport@nib.co.nz?subject=Invitation%20request%20for%20nib%20adviser%20access',
  },
  {
    title: 'Get in touch',
    subtitle: 'Want to work with us?',
    url: 'mailto:advisersupport@nib.co.nz?subject=Request%20to%20become%20an%20adviser',
  },
  {
    title: 'Download our nib adviser access guide',
    url: helpDoc?.file?.url,
  },
];

const adviserStepDescription = {
  title: 'How adviser access works for you and your clients',
  subTitle: null,
  footer: null,
};

const adviserStepFeatures = [
  {
    heading: 'Time saving quotes',
    content: function Quotes() {
      return 'Get quick and easy quotes for your clients using nibAPPLY.';
    },
    image: Step01Img,
  },
  {
    heading: 'Easy client sign up',
    content: function SignUp() {
      return 'nibAPPLY is a fast and easy way to sign your clients up to insurance with nib.';
    },
    image: Step02Img,
  },
  {
    heading: 'Simple document access',
    content: function DocumentAccess() {
      return 'Download and view adviser documents and your nib client portfolio.';
    },
    image: Step03Img,
  },
  {
    heading: 'Quick access to member details',
    content: function MemberDetails() {
      return 'Search your member listing to see client contact details, policy documents and premium change letters.';
    },
    image: Step04Img,
  },
  {
    heading: 'Up to date adviser information',
    content: function AdviserInfo() {
      return 'Have all the latest adviser information at your fingertips including brochures, forms and help guides.';
    },
    image: Step05Img,
  },
];

interface ContactUsProps {
  keyContactList: ContentfulDocument;
}
const ContactUsSection = ({ keyContactList }: ContactUsProps) => (
  <Section>
    <Stack space={{ xs: 4, md: 6 }}>
      <Heading size={{ sm: 4, md: 3, lg: 2 }} component="p" color="trueGreen">
        Need to talk to us directly?
      </Heading>
      <Copy measure={false}>
        All the information you’ll need about your clients and their policies is available through
        nib adviser access. If you need extra help, contact our dedicated support team.
      </Copy>
      <SecondaryButton href={keyContactList?.file?.url}>Contact Us</SecondaryButton>
    </Stack>
  </Section>
);

const WhyWorkWithUsPanel = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${breakpoint('md')`
    margin: 0px -12px;
  `};
`;

const WhyWorkWithUsCard = styled(Card)`
  > div:first-child {
    div {
      color: ${colorTrueGreen};
    }
  }
  h3 {
    color: ${colorTrueGreen};
  }

  margin: 12px 0px;
  ${breakpoint('md')`
    flex-basis: 48%;
    margin: 1%;
  `};
  ${breakpoint('lg')`
    flex-basis: 31%;
    margin: 1%;
  `};
`;

interface WhyWorkWithUsProps {
  keyContactList: ContentfulDocument;
}
const WhyWorkWithUsSection = ({ keyContactList }: WhyWorkWithUsProps): JSX.Element => {
  return (
    <Section background="sageGreen60">
      <Stack space={5}>
        <Heading size={{ sm: 4, md: 3, lg: 2 }} component="p" color="trueGreen" align="center">
          Why work with us
        </Heading>

        <WhyWorkWithUsPanel>
          <HealthComesFirstTile />
          <ComprehensiveCoverTile />
          <DigitalToolsTile />
          <CommercialTermsTile />
          <DedicatedSupportTile />
        </WhyWorkWithUsPanel>

        <Copy measure={false}>
          We’ll help you have healthy conversations with your clients and are committed to
          supporting you. Get in touch with an Adviser Partner Manager near you.
        </Copy>
        <SecondaryButton href={keyContactList?.file?.url}>Become an nib agency</SecondaryButton>
      </Stack>
    </Section>
  );
};

const HealthComesFirstTile = (): JSX.Element => {
  return (
    <WhyWorkWithUsCard padding={3} height="100%" icon={HealthInsuranceGraphicIcon} align="center">
      <Card.Content title="A Healthier Approach">
        <Copy align="center">
          nib’s A Healthier Approach is about making health and life insurance clearer, more
          accessible, and better suited to real life. We provide the tools, insights, and
          flexibility to help Kiwi get the right cover, make informed decisions, and take control of
          their wellbeing.
        </Copy>
      </Card.Content>
    </WhyWorkWithUsCard>
  );
};

const ComprehensiveCoverTile = (): JSX.Element => {
  return (
    <WhyWorkWithUsCard padding={3} height="100%" icon={WellnessScoreGraphicIcon} align="center">
      <Card.Content title="Comprehensive cover">
        <Copy align="center">
          We have a range of cover options that can be mixed and matched to suit your client's needs
          and budget. But we're more than just great cover, we help members navigate their health
          journey and promote proactive care.
        </Copy>
      </Card.Content>
    </WhyWorkWithUsCard>
  );
};

const DigitalToolsTile = (): JSX.Element => {
  return (
    <WhyWorkWithUsCard padding={3} height="100%" icon={ComputerAiGraphicIcon} align="center">
      <Card.Content title="Digital tools to service you and your clients">
        <Copy align="center">
          We make it faster and easier online. You can streamline your client’s application through
          nibAPPLY and your clients can access the nib app to manage their policy and claim on the
          spot.{' '}
        </Copy>
      </Card.Content>
    </WhyWorkWithUsCard>
  );
};

const CommercialTermsTile = (): JSX.Element => {
  return (
    <WhyWorkWithUsCard padding={3} height="100%" icon={PriceGraphicIcon} align="center">
      <Card.Content title="Attractive commercial terms">
        <Copy align="center">
          We value your business and the quality advice you provide, and this is reflected in the
          commercial terms available.
        </Copy>
      </Card.Content>
    </WhyWorkWithUsCard>
  );
};

const DedicatedSupportTile = (): JSX.Element => {
  return (
    <WhyWorkWithUsCard
      padding={3}
      height="100%"
      icon={MultiYearPartnershipsGraphicIcon}
      align="center"
    >
      <Card.Content title="Dedicated support">
        <Copy align="center">
          Our team of Adviser Partner and Relationship Managers, plus our Customer Care team provide
          ongoing support for you and your clients so you can recommend nib with confidence.
        </Copy>
      </Card.Content>
    </WhyWorkWithUsCard>
  );
};

interface GraphQLAdviserDocumentNode {
  edges: ContentfulAdviserDocumentEdgeShape[];
}

interface ContentfulAdviserDocumentEdgeShape {
  node: {
    document: ContentfulDocument;
  };
}

interface AdviserPageProps {
  data: {
    keyContactList: GraphQLAdviserDocumentNode;
    helpDoc: GraphQLAdviserDocumentNode;
  };
}

const AdviserPage = (props: AdviserPageProps) => {
  const keyContactList = props?.data?.keyContactList?.edges?.[0]?.node?.document;
  const helpDoc = props?.data?.helpDoc?.edges?.[0]?.node?.document;
  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <HeroPanel
        images={{
          desktop: DesktopHero,
          desktop2x: DesktopHero2x,
          mobile: MobileHero,
          mobile2x: MobileHero2x,
          tablet: TabletHero,
          tablet2x: TabletHero2x,
        }}
        title="nib adviser access"
        nextSectionBackgroundColor={colors.sneezy}
        variation="condensed"
      >
        <HeroPanelCopy>
          Easy access to nibAPPLY and everything you need to support and manage your nib client
          policies on the go.
        </HeroPanelCopy>
        <PrimaryButton href={config.adviserAccessUrl}>Login</PrimaryButton>
      </HeroPanel>
      <Section background="warmWhite60">
        <UtilityButtons buttons={utilityLinks(helpDoc)} flex={true} />
      </Section>
      <Section>
        <VerticalFeaturePanel
          description={adviserStepDescription}
          features={adviserStepFeatures}
          useAnimation={true}
        />
      </Section>
      <WhyWorkWithUsSection keyContactList={keyContactList} />
      <ContactUsSection keyContactList={keyContactList} />
    </Layout>
  );
};

export default metrics({ pageName: 'adviser' })(AdviserPage);
